import React from "react"
import { Link } from "gatsby"

const Pagination = props => {
  const { numberOfPages, currentPage } = props
  const isFirst = currentPage === 1
  const isLast = currentPage === numberOfPages
  const isNotPaginated = isFirst & isLast
  const prevPageNum = currentPage - 1 === 1 ? `` : currentPage - 1
  const nextPageNum = currentPage + 1
  const prevPageLink = isFirst ? null : `/articles/${prevPageNum}/`
  const nextPageLink = isLast ? null : `/articles/${nextPageNum}/`

  return (
    <div>
      {!isFirst && <Link to={prevPageLink}>&#8592; Prev Page</Link>}
      {!isNotPaginated && (
        <div>
          {currentPage}/{numberOfPages}
        </div>
      )}
      {!isLast && <Link to={nextPageLink}>Next Page &#8594;</Link>}
    </div>
  )
}

export default Pagination
