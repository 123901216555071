import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import SEO from "../SEO"
import { slugify } from "../util/utilityFunctions"
import Pagination from "../components/pagination"

const postList = props => {
  const posts = props.data.allContentfulBlogPost.edges
  const { currentPage, numberOfPages } = props.pageContext

  return (
    <Layout>
      <SEO title={`Content Visit | Page: ${currentPage}`} />
      <div className="container">
        {posts.map(({ node }, index) => (
          <Link key={index} to={`/article/${slugify(node.title)}`}>
            <h2>{node.title}</h2>
            {/* <p>{node.publishDate}</p> */}
          </Link>
        ))}
        <span>{`Page: ${currentPage} +${numberOfPages}`}</span>
        <Pagination currentPage={currentPage} numberOfPages={numberOfPages} />
      </div>
    </Layout>
  )
}

export const postListQuery = graphql`
  query postListQuery($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      sort: { fields: publishDate, order: ASC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          publishDate(formatString: "MMMM Do, YYYY")

          body {
            json
          }
        }
      }
    }
  }
`
export default postList
